import { render, staticRenderFns } from "./layoutFooter.vue?vue&type=template&id=2ea7a603&scoped=true&lang=pug&"
import script from "./layoutFooter.vue?vue&type=script&lang=js&"
export * from "./layoutFooter.vue?vue&type=script&lang=js&"
import style0 from "./layoutFooter.vue?vue&type=style&index=0&id=2ea7a603&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea7a603",
  null
  
)

export default component.exports