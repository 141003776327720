<script>
export default {
  name: 'Footer'
}
</script>

<template lang="pug">
  .footer-index
    div
      img(src='@/assets/images/logos/dokoma-white.svg', height='20px')
      div {{ $t('menu.droits-dauteurs') }} {{ new Date().getFullYear() }}
    div.flex-grow-1
    //- div.flex.flex-column.items-end
      div.white--text.f5 Besoin d'aide?
      a.tr.white--text(href='https://blahblah.dokoma.com/clavardage', target='_blank') Venez discuter
</template>

<style lang='sass' scoped>
.footer-index
  background-color: black
  color: white
  padding: 50px $responsive-gutter-small
  margin: 0 20px 0 calc(375px + 20px)
  width: calc(100% - 40px - 375px)
  height: 100%
  max-height: 135px
  font-size: 12px
  display: flex
  z-index: 1
  border-radius: $border-radius-root $border-radius-root 0 0
  @media (min-width: $medium) and (max-width: $large)
    width: calc(100% - 100px - 20px)
    margin-left: calc(80px + 20px)
    margin-right: 20px
  @media all and (max-width: $medium)
    border-radius: 0
    width: 100%
    margin: 0
    padding: 50px $responsive-gutter-small !important
</style>
