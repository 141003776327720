<script>
export default {
  name: 'RetirerEnseignant',
  data () {
    return {
      loadingSuppression: false
    }
  },
  computed: {
    dialogData () {
      return this.$store.state.Dialog.dialogData
    }
  },
  methods: {
    deleteEnseignant () {
      const { enseignant } = this.dialogData
      this.removeUsagerFromCompte(enseignant)
    },
    async removeUsagerFromCompte (enseignant) {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/retirerSalleClasseCompte.gql'),
        variables: { usager: enseignant }
      })

      if (!data?.retirerSalleClasseCompte?.id || data.retirerSalleClasseCompte?.errors) {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'error',
          description: this.$t('alerte.suppr-compte-enseignant-error')
        })
      } else {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'success',
          description: this.$t('alerte.suppr-compte-enseignant-success')
        })
      }
      this.$store.commit('App/setGlobalDialog', { value: false, context: null })
      this.$store.dispatch('Compte/fetchCompte')
      this.$router.push({ name: 'enseignants___fr' })
    }
  }
}
</script>

<template lang="pug">
  v-card.light
    v-card-title.dialog-title.secondary.white--text Retirer un enseignant
    v-card-text.black--text
      p.f5.tc.my-5 Etes-vous sûr de vouloir retirer cet enseignant de l'école ?
      .flex.justify-space-around.w-100.action-sans-relation.mt-2
        v-btn.mr2(color='secondary' x-large @click='$emit("close")',) Annuler
        v-btn.mr2.button-deux(color='secondary' x-large @click="deleteEnseignant") Confirmer
</template>
