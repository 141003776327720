<script>
import BackgroundTexture from '@/components/home/backgroundTexture'
import LayoutFooter from '@/components/layout/layoutFooter'
import LayoutTopBanner from '@/components/layout/layoutTopBanner'
import NavigationSidebar from '@/components/layout/navigationSidebar.vue'
import GlobalDialog from '@/components/shared/globalDialog'
import FeedbackSnackbar from '@/components/shared/feedbackSnackbar.vue'

import { storeToComputed } from '@/plugins/storeToComputed'

export default {
  name: 'App',
  components: {
    BackgroundTexture,
    LayoutFooter,
    LayoutTopBanner,
    NavigationSidebar,
    GlobalDialog,
    FeedbackSnackbar
  },
  data () {
    return {
    }
  },
  mixins: [],
  computed: {
    footerVisible () {
      const listRouteNoFooter = ['connect']
      if (this.$route?.name) {
        return !listRouteNoFooter.find(e => this.$route.name.includes(e))
      } else {
        return false
      }
    },
    ...storeToComputed(['windowSize', 'isMobile'])
  },
  async created () {
  },
  async mounted () {
    // Listen for window resize and run handleResize once DOM updated
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => {
      this.handleResize()
    })

    await this.$store.dispatch('User/fetchRelations')
    if (this.$store.state.User.user && this.$store.state.User.user.info && this.$store.state.User.relations.total === 0 && !this.$store.state.App.globalDialog.value) {
      this.$store.commit('App/setGlobalDialog', { value: true, context: 'sansRelation' })
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler (newVal) {
        if (newVal && newVal.name) {
          // Pour afficher drift seulement sur l'accueil.
          if (window.document.getElementsByClassName('drift-conductor-item drift-frame-controller')) {
            let widget = window.document.getElementsByClassName('drift-conductor-item drift-frame-controller')
            if (widget && widget.length > 0) {
              if (newVal.name === 'home') {
                widget[0].style.display = 'block'
              } else {
                widget[0].style.display = 'none'
              }
            }
          }
          if (this.$store.state.User.user?.id) {
            // on check si on a un compte validé
            // !!!!!!!!!!!!!!!!!!!!!!!!! TODO DECOMMENT QUAND DISPO BACKEND !!!!!!!!!!!!!
            if (!this.$store.state.User.user.porterAuCompte) {
              this.$store.commit('App/setGlobalDialog', { value: true, context: 'waitingValidation' })
            } else if (this.$store.state.App.globalDialog.value) {
              this.$store.commit('App/setGlobalDialog', { value: false, context: null })
            }
          }
        }
      }
    }
  },
  methods: {
    checkRelation () {

    },
    handleResize () {
      const width = window.innerWidth
      const height = window.innerHeight
      this.windowSize = { width, height }
    }
  }
}
</script>
<template lang='pug'>
  v-app
    //- notre dialog global au site
    global-dialog
    feedback-snackbar
    background-texture(v-if='$route.name && !$route.name.includes("connect")')
    layout-top-banner
    navigation-sidebar.sidebar(v-if='$store.state.User.connected')
    v-main
      vue-page-transition(name='fade-in-right')
        router-view.applicationEcole
    layout-footer(v-if='footerVisible')
</template>

<style lang='sass'>
  @import 'src/styles/vendors/_vuetify'
  @import 'src/styles/vendors/_v-tooltip'

  @import 'src/styles/layout/_navigation'
  @import 'src/styles/components/dialogs/_basicDialog'
  @import 'src/styles/components/dialogs/_selectionDialog'

  @import 'src/styles/components/shared/_pageActions'
  @import 'src/styles/components/shared/_splitPageLayout'
  @import 'src/styles/components/shared/_common'

  @import 'src/styles/components/presentation/_baseCard'

  @import 'src/styles/vendors/_drift-widget.sass'
  .ps__rail-x, .ps__rail-y
    background-color: white
    opacity: 0 !important
  body
    margin: 0 !important
  .drift-frame-controller
    width: 80px !important
  .ML__keystroke-caption
    padding: 0 !important
  .vuetify-badge
    .v-badge__badge
      .v-icon
        font-size: 12px !important
        color: white
  .v-btn__content
    text-transform: none
    letter-spacing: normal
  .v-navigation-drawer__border
    display: none
  .v-application
    white-space: pre-line
    transition: background-color 0.5s ease-in
    background-color: var(--v-accent-base) !important
    &.theme-dark
      background-color: var(--v-accent-base) !important
    &.login-view
      .v-main
        .v-main__wrap
          padding: 0
    .v-main
      // display: flex
      padding-top: 40px !important
      .v-main__wrap
        padding: 20px
    @media all and (min-width: $medium)
      &.custom-layout-view
        .v-main
          padding-left: 80px !important
    @media all and (max-width: $medium)
      .v-main
        .v-main__wrap
          padding: 0
      &.footer-visible
        padding-bottom: 56px
    @media all and (max-width: $small)
      .v-main
        .v-main__wrap
          padding: 0
</style>
