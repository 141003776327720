const state = {
  isAuthenticated: false,
  ajoutEnseignant: false,
  distributeCredit: false,
  classeManagement: false,
  globalDialog: { value: false, context: null },
  showSnackbarGlobal: false,
  snackbarGlobalContent: {
    title: 'Titre par défaut',
    description: 'description',
    timeout: 4000,
    type: 'success'
  },
  language: 'fr',
  sideNav: false,
  bearer: null,
  connectError: false,
  window: {
    width: 1200,
    height: 800
  },
  mobileTemplate: false
}

const getters = {}

const actions = {
  setSnackBarNotification ({ commit, state }, value) {
    commit('setSnackbarGlobalContent', value)
    commit('setShowSnackbarGlobal', true)
  }
}

const mutations = {
  setConnectError (state, payload) {
    state.connectError = payload
  },
  setBearer (state, payload) {
    state.bearer = payload
  },
  setSideNav (state, payload) {
    state.sideNav = payload
  },
  setLanguage (state, payload) {
    state.language = payload
  },
  setShowSnackbarGlobal (state, payload) {
    state.showSnackbarGlobal = payload
  },
  setSnackbarGlobalContent (state, payload) {
    state.snackbarGlobalContent = payload
  },
  setGlobalDialog (state, payload) {
    state.globalDialog = payload
  },
  setWindowSize (state, { width, height }) {
    state.window.width = width
    state.window.height = height
    const mobileTemplate = width < 900
    if (mobileTemplate !== state.mobileTemplate) {
      state.mobileTemplate = mobileTemplate
    }
  },
  isAuthenticated (state, payload) {
    state.isAuthenticated = payload.isAuthenticated
  },
  showAjoutEnseignant (state, payload) {
    state.ajoutEnseignant = payload
    state.globalDialog = payload
  },
  showDistributeCredits (state, payload) {
    state.distributeCredit = payload
    state.globalDialog = payload
  },
  showClasseManagement (state, payload) {
    state.classeManagement = payload
    state.globalDialog = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
