import VueI18n from 'vue-i18n'
import Vue from 'vue'
import messagesFR from '../lang/fr.json'
import messagesEN from '../lang/en.json'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'en',
  messages: {
    fr: messagesFR,
    en: messagesEN
  }
})
