import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { i18n } from '../setup/i18n.js'
import VueCookies from 'vue-cookies'
// import { apolloClientV2 } from '../setup/apollo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang/accueil',
    name: 'home___fr',
    component: () => import('../views/home.vue')
  },
  {
    path: '/:lang/home',
    name: 'home___en',
    component: () => import('../views/home.vue')
  },
  {
    path: '/:lang/connexion',
    name: 'connect___fr',
    component: () => import('../views/connect.vue')
  },
  {
    path: '/:lang/connect',
    name: 'connect___en',
    component: () => import('../views/connect.vue')
  },
  {
    path: '/:lang/auth',
    name: 'auth', // pas de check de lang ici
    component: () => import('../views/auth.vue')
  },
  {
    path: '/:lang/enseignants',
    name: 'enseignants___fr',
    component: () => import('../views/enseignants/index.vue')
  },
  {
    path: '/:lang/teachers',
    name: 'enseignants___en',
    component: () => import('../views/enseignants/index.vue')
  },
  {
    path: '/:lang/enseignants/:id',
    name: 'enseignantdetail___fr',
    component: () => import('../views/enseignants/_id.vue')
  },
  {
    path: '/:lang/teachers/:id',
    name: 'enseignantdetail___en',
    component: () => import('../views/enseignants/_id.vue')
  },
  {
    path: '/:lang/classes',
    name: 'classes___fr',
    component: () => import('../views/classes/index.vue')
  },
  {
    path: '/:lang/produits',
    name: 'produits___fr',
    component: () => import('../views/bibliotheque/index.vue')
  },
  {
    path: '/:lang/products',
    name: 'produits___en',
    component: () => import('../views/bibliotheque/index.vue')
  },
  {
    path: '/:lang/produits/:id',
    name: 'produitsdetail___fr',
    component: () => import('../views/bibliotheque/_produit.vue')
  },
  {
    path: '/:lang/products/:id',
    name: 'produitsdetail___en',
    component: () => import('../views/bibliotheque/_produit.vue')
  },
  {
    path: '/:lang/classrooms',
    name: 'classes___en',
    component: () => import('../views/classes/index.vue')
  },
  {
    path: '/:lang/classes/:id',
    name: 'classesdetail___fr',
    component: () => import('../views/classes/_id.vue')
  },
  {
    path: '/:lang/classes/:id',
    name: 'classesdetail___en',
    component: () => import('../views/classes/_id.vue')
  },
  {
    path: '/:lang/profil',
    name: 'profil___fr',
    component: () => import('../views/profil.vue')
  },
  {
    path: '/:lang/profil',
    name: 'profil___en',
    component: () => import('../views/profil.vue')
  },
  {
    path: '/:lang/commandes',
    name: 'commandes___fr',
    component: () => import('../views/commandes/index.vue')
  },
  {
    path: '/:lang/commandes',
    name: 'commandes___en',
    component: () => import('../views/commandes/index.vue')
  },
  {
    path: '/:lang/commandes/:id',
    name: 'commandesdetail___en',
    component: () => import('../views/commandes/_id.vue')
  },
  {
    path: '/:lang/commandes/:id',
    name: 'commandesdetail___fr',
    component: () => import('../views/commandes/_id.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)

  // if i18n doesnt have a locale, use the store locale (fr by default)
  if (!i18n.locale) {
    i18n.locale = store.state.App.language
  }

  // Lang params not found, redirect to home
  if (!to.params.lang) {
    return next({ name: 'home___' + i18n.locale, params: { lang: i18n.locale } })
  }

  // If route lang is not the same as i18n locale, overwrite i18n and store with route lang
  if (to.params.lang !== i18n.locale) {
    store.commit('App/setLanguage', to.params.lang)
    i18n.locale = to.params.lang
  }

  // If Authentication cookie is found (excluding Auth route)
  if (to.name !== 'auth' && Vue.$cookies.get('EcoleDokomaUser')) {

    // on va chercher user
    const needToCheckAccount = store.state.User.checkAccount
    const oauthTokenIsPresentButNotBearer = VueCookies.get('EcoleDokomaUser').oauth_token && !VueCookies.get('EcoleDokomaUser').bearer
    const goingToProfilePage = to.name.includes('profil___')
    if (needToCheckAccount || oauthTokenIsPresentButNotBearer) {
      if (!goingToProfilePage) {
        return next({ name: 'profil___' + i18n.locale, params: { lang: i18n.locale }, query: { create: true } })
      }
    }

    // If connected is not set in the store, try to login before continue
    // This happens when a user is connected but refresh the page or open in a new tab
    if (!store.state.User.connected) {
      await store.dispatch('User/fetchMinProfile')
    }

    // User is not connected and does not have Route Query property, redirect him to connect page
    if (!store.state.User.connected && Object.keys(from.query).length === 0 && to.name !== 'auth' && !to.name.includes('connect___')) {
        return next({ name: 'connect___' + i18n.locale, params: { lang: i18n.locale } })
    }
    // User is connected but tries to go to Connect page, redirect him to homepage
    if (store.state.User.connected && to.name.includes('connect___')) {
        return next({ name: 'home___' + i18n.locale, params: { lang: i18n.locale } })
    }

  } else {
    // Authentication cookie was not found

    // If user is not going to connect or Auth, then redirect him to Connect page
    if (to.name !== 'connect___' + i18n.locale && to.name !== 'auth') {
      return next({ name: 'connect___' + i18n.locale, params: { lang: i18n.locale } })
    }
  }
  // If no redirection was done, just go on your way...
  return next()
})

export default router
