<<script>
import TableauEnseignants from '@/components/shared/tableauUsers'
import handleUser from '@/mixins/handleUser'

export default {
  name: 'RechercheEnseignants',
  mixins: [handleUser],
  props: {
    creation: { required: false, type: Boolean, default: false }
  },
  components: {
    TableauEnseignants
  },
  data () {
    return {
      enseignants: [],
      nombreEnseignants: 0,
      page: 1,
      loading: true
    }
  },
  apollo: {
    enseignantsQuery: {
      query: require('@/graphql/queries/v2/searchSalleClasseUsagers.gql'),
      variables () {
        return {
          // q: this.qSearch,
          filtre: {
            roles: ['SALLE_CLASSE_ENSEIGNANT']
          },
          compagnies: [this.$store.state.Compte?.compte?.compagnie?.id],
          page: 1
        }
      },
      update (data) {
        // Might break if the number of teachers exceed 1 page
        if (this.page === 1) {
          this.nombreEnseignants = data.searchSalleClasseUsagers.total
          const enseignantsNotAlreadyInSchool = data.searchSalleClasseUsagers.resultat
          this.enseignants = enseignantsNotAlreadyInSchool
        }
      },
      watchLoading (loading) {
        this.loading = loading
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    formattedEnseignant () {
      return this.enseignants.filter(e => {
        return !this.isEnseignantInTheCompte(e.id)
      }).map(e => {
        return {
          prenom: e.info?.prenom,
          nom: e.info?.nom,
          courriel: e.info?.courriel,
          username: e.info?.username,
          loading: !!e.loading
        }
      })
    }
  },
  methods: {
    isEnseignantInTheCompte (enseignantId) {
      const enseignants = this.$store.getters['Compte/enseignants']
      return !!enseignants.find(e => e.id === enseignantId)
    },
    async approve (enseignant) {
      const { prenom, nom, courriel, username } = enseignant
      this.setEnseignantLoading(username, true)

      await this.createAccount([{ prenom, nom, courriel }])

      this.$emit('refetch')
    },
    setEnseignantLoading (username, loadingStatus) {
      this.enseignants.forEach((e, index) => {
        if (e.info.username === username) {
          e.loading = loadingStatus
          this.enseignants.splice(index, 1, e)
        }
      })
    }
  }
}
</script>

<template lang="pug">
.recherche-enseignants.mt-3
  p.f4.pl-2.pr-3(v-html="$t('action.selection-enseignant-etab', { compagnie: $store.getters['Compte/compagnieName'] })")

  span.f4.ml3(v-if="!enseignants.length && loading") {{ $t('loading.loading') }}

  v-list.my-4(two-line)
    v-list-item(v-for="e in formattedEnseignant" :key="e.username")
      v-list-item-content
        v-list-item-title {{ `${e.prenom} ${e.nom}` }}
        v-list-item-subtitle @{{ e.username }} | {{ e.courriel }}
      v-spacer
      v-btn(color="success" @click="approve(e)" :loading="e.loading") {{ $t('enseignant.approve-enseignant') }}

</template>

<style lang='sass' scoped>
.v-list
  max-height: 420px
  overflow-y: auto
  .v-list-item:nth-child(odd)
    background-color: #eee
</style>
