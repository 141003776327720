<script>
export default {
  name: 'CustomNameList',
  data () {
    return {
      customNamesList: [{ prenom: null, nom: null, courriel: null }],
      courrielRules: [
        v => !!v || this.$i18n.t('rules.courriel-obligatoire'),
        v => /.+@.+/.test(v) || this.$i18n.t('rules.courriel-invalide'),
        () => !this.courrielExist || this.$i18n.t('rules.courriel-existant')
      ]
    }
  },
  methods: {
    deleteEntry (index) {
      this.customNamesList.splice(index, 1)
      this.setList()
    },
    addNewStudent () {
      const emptyName = { prenom: null, nom: null, courriel: null }
      this.customNamesList.push(Object.assign({}, emptyName))
    },
    setList () {
      const [lastNames] = this.customNamesList.slice(-1)
      const isLastEmpty = !lastNames.prenom && !lastNames.nom && !lastNames.courriel
      const isNewNeeded = !isLastEmpty && (!lastNames.prenom || !lastNames.nom || !lastNames.courriel)
      if (isNewNeeded) {
        this.customNamesList.push({ prenom: null, nom: null, courriel: null })
      }

      const [lastNamesAgain] = this.customNamesList.slice(-1)
      const isLastEmptyAgain = !lastNamesAgain.prenom && !lastNamesAgain.nom && !lastNamesAgain.courriel
      const filteredList = isLastEmptyAgain ? this.customNamesList.slice(0, -1) : this.customNamesList
      this.$emit('setList', [...filteredList])
    }
  }
}
</script>

<template lang="pug">
.custom-name-list
  table.w-100
    tr
      th.tl {{$t("partage.prenom-maj")}}
      th.tl.w-25 {{$t("partage.nom-maj")}}
      th.tl {{$t("partage.email-maj")}}
    tr(v-for='(items, index) in customNamesList')
      td
        v-text-field.mb1.mr1(:placeholder='$t("partage.prenom-maj")', hide-details, v-model='customNamesList[index].prenom', dense, @input='setList')
      td.w-25
        v-text-field.mb1.ml1(:placeholder='$t("partage.nom-maj")', hide-details, v-model='customNamesList[index].nom', dense, @input='setList')
      td
        v-text-field.mb1.ml1(:placeholder="$tc('form.courriel', 2)", :rules='courrielRules', hide-details, v-model='customNamesList[index].courriel', dense, @input='setList')
      td.w-10
        v-btn(icon, small, @click='deleteEntry(index)', tabindex='-1')
          font-awesome-icon.f6(:icon="['fad', 'trash-alt']")
</template>

<style lang="sass" scoped>
.custom-name-list
  max-height: 450px
  overflow-y: auto
  overflow-x: hidden
</style>
