<<script>
export default {
  name: 'TableauUser',
  props: {
    users: { required: false, type: Array, default: () => [] },
    total: { required: false, type: Number, default: 0 },
    loading: { required: false, type: Boolean, default: false },
    selectable: { required: false, type: Boolean, default: false },
    preSelection: { required: false, type: Array, default: () => [] }
  },
  data () {
    return {
      userSelection: []
      // headers: [
      //   { text: this.$t('partage.nom'), value: 'nom' },
      //   { text: this.$t('partage.prenom'), value: 'nom' },
      // ]
    }
  },
  computed: {
    headers () {
      const temp = [
        { text: this.$t('partage.nom'), value: 'info.nom' },
        { text: this.$t('partage.prenom'), value: 'info.prenom' }
      ]
      // if (this.selectable) {
      //   temp.push({ text: this.$t('partage.selectionne'), value: 'actions', sortable: false })
      // }
      return temp
    }
  },
  watch: {
    preSelection: {
      immediate: true,
      deep: true,
      handler (newVal) {
        this.userSelection = newVal
      }
    }
  },
  methods: {
    updateSelection (users) {
      console.log('le user', users)
      this.$emit('updateSelection', users)
    }
  }
}
</script>

<template lang="pug">
  .tableau-user
    v-data-table(
      v-model='userSelection',
      :headers='headers',
      :items='users',
      :loading='loading',
      :show-select='selectable',
      item-key='id',
      @input='updateSelection',
      light
    )
</template>

<style lang='sass'>
.v-input--selection-controls__input
  text-align: center
</style>
