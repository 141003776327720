import { render, staticRenderFns } from "./layoutTopBanner.vue?vue&type=template&id=0a6f204c&scoped=true&lang=pug&"
import script from "./layoutTopBanner.vue?vue&type=script&lang=js&"
export * from "./layoutTopBanner.vue?vue&type=script&lang=js&"
import style0 from "./layoutTopBanner.vue?vue&type=style&index=0&id=0a6f204c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6f204c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VAppBar,VBtn,VHover})
