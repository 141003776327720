import { render, staticRenderFns } from "./navigationSidebar.vue?vue&type=template&id=1c86cc85&scoped=true&lang=pug&"
import script from "./navigationSidebar.vue?vue&type=script&lang=js&"
export * from "./navigationSidebar.vue?vue&type=script&lang=js&"
import style0 from "./navigationSidebar.vue?vue&type=style&index=0&id=1c86cc85&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c86cc85",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDivider,VList,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})
