import { apolloClientV2, apolloClientBoutique } from '@/setup/apollo.js'
import VueCookies from 'vue-cookies'

const state = {
  user: null,
  checkAccount: false,
  connected: false,
  relations: { total: 0, resultat: [] },
  bearers: {}
}

const getters = {
  enseignants: state => {
    const r = state.relations.resultat
    return r?.length ? r.map(relation => relation.inferieur) : []
  }
}

const actions = {
  logout ({ commit }) {
    commit('setConnected', false)
    commit('setUser', null)
    VueCookies.remove('EcoleDokomaUser', '/', process.env.COOKIES_HOST)
  },
  async loginUser ({ commit, dispatch, rootState }, value) {
    const cookieUpdate = {
      bearer: null,
      oauth_token: value.idConnexion.access_token || value.idConnexion,
      user: value.user
    }
    await apolloClientBoutique.mutate({
      mutation: require('@/graphql/mutations/login.gql'),
      variables: {
        provider: value.user.provider,
        uid: value.user.id
      }
    }).then(({ data }) => {
      if (data.login) {
        cookieUpdate.bearer = data.login
        // on a un bearer et on mets à jour le cookie
        commit('App/setBearer', data.login, { root: true })
        dispatch('fetchMinProfile', value)
        VueCookies.set('EcoleDokomaUser', JSON.stringify(cookieUpdate), 60 * 60 * 24, '/', process.env.COOKIES_HOST)
        value.router.push({ name: 'home___' + value.i18n.locale, params: { lang: value.i18n.locale } })
      } else {
        commit('setCheckAccount', true)
        cookieUpdate.justConnect = true
        VueCookies.set('EcoleDokomaUser', JSON.stringify(cookieUpdate), 60 * 60 * 24, '/', process.env.COOKIES_HOST)
        if (value.router) {
          value.router.push({ name: 'profil___' + value.i18n.locale, params: { lang: value.i18n.locale }, query: { create: true } })
        }
      }
    })
  },
  async fetchMinProfile ({ commit, state, dispatch }, ctx) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerMin.gql'),
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        if (data.salleClasseUsager?.roles?.find(e => e.includes('administration'))) {
          // on check si le cookie contient justConnect et on l'enleve si il faut
          if (VueCookies.get('EcoleDokomaUser')?.justConnect) {
            const cookitemp = VueCookies.get('EcoleDokomaUser')
            VueCookies.set('EcoleDokomaUser', JSON.stringify({
              bearer: cookitemp.bearer,
              oauth_token: cookitemp.oauth_token,
              user: cookitemp.user
            }), 60 * 60 * 24, '/', process.env.COOKIES_HOST)
          }
          commit('setConnected', true)
          commit('setCheckAccount', false)
          commit('setUser', data.salleClasseUsager)
          dispatch('Compte/fetchCompte', null, { root: true })
        } else {
          if (data.salleClasseUsager) {
            commit('App/setConnectError', true, { root: true })
            dispatch('logout')
            // commit('setConnected', false)
            // commit('setUser', null)
            // VueCookies.remove('EcoleDokomaUser', '/', process.env.COOKIES_HOST)
          } else {
            if (VueCookies.get('EcoleDokomaUser').justConnect) {
              commit('setConnected', false)
              commit('setCheckAccount', true)
              commit('setUser', null)
            } else {
              dispatch('logout')
            }
          }
        }
      })
    } catch (e) {
      dispatch('logout')
      console.error('erreur lors de la récupération du compte', e)
    }
  },
  async fetchRelations ({ commit, state }) {
    try {
      await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseUsagerRelations.gql'),
        variables: { natures: ['AUTORITE'], limit: 100 },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        if (data.salleClasseUsagerRelations) {
          commit('setRelations', data.salleClasseUsagerRelations)
        } else {
          throw Error('erreur fetchRelations')
        }
      })
    } catch (e) {
      console.error('erreur lors de la récupération des relations', e)
    }
  }
}

const mutations = {
  setRelations (state, payload) {
    state.relations.total = payload.total
    state.relations.resultat = payload.resultat
  },
  setUser (state, payload) {
    state.user = payload
  },
  setCheckAccount (state, payload) {
    state.checkAccount = payload
  },
  setConnected (state, payload) {
    state.connected = payload
  },
  addBearer (state, { username, bearer }) {
    state.bearers[username] = bearer
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
