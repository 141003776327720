<script>
import { storeToComputed } from '@/plugins/storeToComputed'

export default {
  name: 'NavigationSidebar',
  computed: {
    ...storeToComputed(['windowSize', 'mobileTemplate', 'user', 'compte']),
    expandMenuConditions () {
      return this.windowSize.width > 900 && this.windowSize.width < 1200
    },
    sidebarContentList () {
      return [
        {
          link: { to: { name: 'profil___' + this.$i18n.locale, params: { lang: this.$i18n.locale } }, exact: true },
          icon: ['fad', 'school'],
          label: this.$t('menu.mon-ecole')
        },
        {
          link: { to: { name: 'enseignants___' + this.$i18n.locale, params: { lang: this.$i18n.locale } }, exact: true },
          icon: ['fad', 'chalkboard-teacher'],
          label: this.$t('menu.mes-enseignants')
        },
        {
          link: { to: { name: 'classes___' + this.$i18n.locale, params: { lang: this.$i18n.locale } }, exact: true },
          icon: ['fad', 'users-class'],
          label: this.$t('menu.mes-classes')
        },
        {
          link: { to: { name: 'produits___' + this.$i18n.locale, params: { lang: this.$i18n.locale } }, exact: true },
          icon: ['fad', 'books'],
          label: this.$t('menu.mes-cahiers')
        },
        // {
        //   link: { to: '/calendrier', exact: true },
        //   icon: ['fad', 'calendar-alt'],
        //   label: this.$t('Calendrier')
        // },
        {
          link: { to: { name: 'commandes___' + this.$i18n.locale, params: { lang: this.$i18n.locale } } },
          icon: ['fad', 'shopping-cart'],
          label: this.$t('menu.mes-commandes')
        }
      ]
    }
  }
}
</script>

<template lang="pug">
  v-navigation-drawer.masuperclass.navigation-sidebar(
    app,
    disable-route-watcher,
    width='375',
    mini-variant-width='80',
    :permanent="!mobileTemplate"
    :mini-variant="expandMenuConditions",
    :expand-on-hover="expandMenuConditions",
    :class='{"minified__side-menu" : expandMenuConditions}'
  )
    perfect-scrollbar.navigation-scroller(:options='{wheelPropagation: false, suppressScrollX: true}')
      div.navigation__inner-content.relative

        //- Header
        router-link.no-underline(:to="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")
          v-list.list-header(dense)
            v-list-item()
              v-list-item-avatar
                img(src='~@/assets/images/logos/dokoma-icon.svg', width='50')
              v-list-item-content
                v-list-item-title(:title="compte ? compte.compagnie.titre : 'Loading...'") {{ compte ? compte.compagnie.titre : 'Loading...' }}

        //- User
        v-list.main-menu__list.mt3(dense)
          v-list-item.user-header
            v-list-item-avatar
              font-awesome-icon(:icon="['fas', 'user']")
            v-list-item-content
              span {{user.info.prenom}} {{ user.info.nom}}

        v-divider

        //- Navigation
        v-list(rounded, dense)
          v-list-item(
            v-for='item in sidebarContentList'
            :key='item.link.to.name'
            active-class='menu-link--active'
            :to="item.link.to"
            :exact="!!item.link.exact"
          )
            v-list-item-icon.icon
              font-awesome-icon(:icon="item.icon")
            v-list-item-content
              v-list-item-title {{ item.label }}
</template>

<style lang="sass" scoped>
@import 'src/styles/layout/_sidebar'

</style>
