<script>
export default {
  name: 'DistributionCredit',
  data () {
    return {
      step: 0,
      listeCredits: [{ title: 'credit1', value: 1 }, { title: 'credit2', value: 2 }],
      creditSelectionne: [],
      listeEnseignants: [{ title: 'enseignant1', value: 1 }, { title: 'enseignant2', value: 2 }],
      enseignantSelectionne: []
    }
  },
  computed: {
    disabledStepper () {
      let temp = true
      if (this.step === 0 && this.creditSelectionne.length > 0) {
        temp = false
      } else if (this.step === 1 && this.enseignantSelectionne.length > 0) {
        temp = false
      } else if (this.step === 2) {
        temp = false
      }
      return temp
    }
  },
  methods: {
    goNext () {
      if (this.step <= 2) {
        this.step++
      } else {
        this.step++
        console.log('FAIRE L ATTRIBUTION')
      }
    }
  }
}
</script>

<template lang="pug">
  .classe-management
    v-card-text
      h5 Gestion des crédits
      div
        v-select(v-if='step === 0', v-model='creditSelectionne', :items='listeCredits', multiple, placeholder='Sélectionner une ou plusieurs licences à attribuer', item-text='title', item-value='value')
        v-select(v-else-if='step === 1', v-model='enseignantSelectionne', :items='listeEnseignants', multiple, placeholder='Sélectionner un ou plusieurs enseignants', item-text='title', item-value='value')
        template(v-else-if='step === 2')
          h6 Récapitulatif
          div
            div Vous allez attribuer les crédits suivants
              div(v-for='(credit, index) in creditSelectionne', :key='index')
                span {{credit}}
            div aux enseignants suivants
              div(v-for='(enseignant, index) in enseignantSelectionne', :key='index')
                span {{enseignant}}
        template(v-else)
          h6 Retour de l'attribution
      div
        v-btn(v-if='step <= 2 && step > 0', @click='step--') Retour
        v-btn(@click='goNext', :disabled='disabledStepper') {{ step === 0 ? 'Continuer' : 'Attribuer' }}
</template>

<style>
</style>
