import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  // customVariables: ['~/assets/main.scss'],
  // treeShake: true,
  theme: {
    options: {
      customProperties: true
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 600,
        md: 900,
        lg: 1200
      }
    },
    themes: {
      light: {
        dark: '#090121',
        light: '#bdfaff',
        primary: '#74c4f2',
        secondary: '#00233d',
        accent: '#bdfaff',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        dark: '#090121',
        light: '#a0a0bf',
        primary: '#74c4f2',
        secondary: '#090121',
        accent: '#423b56',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  }
})
