import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import {
  faAt,
  faUser,
  faExclamationTriangle,
  faExclamationCircle,
  faPlus as fadPlus,
  faMinus as fadMinus,
  faAppleAlt,
  faBooks,
  faBook,
  faBackpack,
  faShoppingCart,
  faHandsHelping,
  faCog,
  faLocation,
  faCalendarDay,
  faCalendarAlt,
  faCalendarPlus,
  faCalendarEdit,
  faCalendarTimes,
  faUsersMedical,
  faUsersClass,
  faUsers,
  faChalkboardTeacher,
  faChalkboard,
  faTrophyAlt,
  faPoll,
  faCommentSmile,
  faPaintBrushAlt,
  faEye,
  faEyeSlash,
  faConstruction,
  faFileDownload,
  faFileEdit,
  faPen,
  faUserClock,
  faHourglassStart,
  faHourglassEnd,
  faShapes,
  faBell,
  faBellExclamation,
  faHistory,
  faPalette,
  faPrint,
  faTrashAlt,
  faWaveSine,
  faHorizontalRule,
  faCircle,
  faLock,
  faLockOpen,
  faGraduationCap,
  faBirthdayCake,
  faSchool,
  faGrinTongueSquint,
  faCommentAltExclamation,
  faBooksMedical,
  faSun,
  faMoonStars,
  faPowerOff,
  faPennant,
  faUserCheck,
  faFileCheck,
  faCheckCircle,
  faTimesCircle,
  faBolt,
  faCloud,
  faSunCloud,
  faClouds,
  faFog,
  faCloudsSun,
  faCloudSunRain,
  faCloudSun,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudDrizzle,
  faCloudHail,
  faSnowflakes,
  faWindsock,
  faHomeLg,
  faHeartCircle,
  faQuestionCircle,
  faSync,
  faSearch,
  faThLarge,
  faList,
  faPeopleArrows,
  faShareSquare,
  faTag,
  faFilePdf,
  faFileAlt,
  faFolders,
  faFolder,
  faFolderOpen,
  faUserPlus,
  faSave,
  faKeyboard,
  faFunction,
  faCalculatorAlt,
  faStickyNote,
  faCommentAltLines,
  faEdit,
  faPlusSquare,
  faAward,
  faInfoCircle,
  faBinoculars,
  faBookReader,
  faCoins
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faTimes as farTimes,
  faPlus as farPlus,
  faMinus as farMinus,
  faHeart as farHeart,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faLongArrowLeft as farLongArrowLeft,
  faLongArrowRight as farLongArrowRight,
  faInfo as farInfo,
  faBook as farBook,
  faGraduationCap as farGraduationCap,
  faGlobeStand as farGlobeStand,
  faFileCertificate as farFileCertificate
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCommentAltExclamation as fasCommentAltExclamation,
  faBooks as fasBooks,
  faHomeLg as fasHomeLg,
  faAppleAlt as fasAppleAlt,
  faBackpack as fasBackpack,
  faBars as fasBars,
  faUser as fasUser,
  faArrowAltDown as fasArrowAltDown,
  faTimes as fasTimes,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faGripVertical as fasGripVertical,
  faHeart as fasHeart,
  faEllipsisV as fasEllipsisV,
  faCheck as fasCheck,
  faHistory as fasHistory,
  faInfo as fasInfo,
  faGripHorizontal as fasGripHorizontal,
  faGripLines as fasGripLines,
  faReply as fasReply,
  faBold as fasBold,
  faItalic as fasItalic,
  faListUl as fasListUl,
  faListOl as fasListOl,
  faUndo as fasUndo,
  faRedo as fasRedo,
  faCircle as fasCircle,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown,
  faInfinity as fasInfinity,
  faDatabase as fasDatabase
} from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  fasDatabase,
  farGraduationCap,
  farGlobeStand,
  farBook,
  faCoins,
  faAt,
  fasHistory,
  faUser,
  fasUser,
  fasBars,
  fasGripVertical,
  faExclamationTriangle,
  faExclamationCircle,
  fadPlus, farPlus,
  fadMinus, farMinus,
  fasArrowAltDown,
  fasArrowRight, farArrowRight,
  fasArrowLeft, farArrowLeft,
  farLongArrowLeft,
  farLongArrowRight,
  fasTimes, farTimes,
  faAppleAlt, fasAppleAlt,
  faBooks, fasBooks,
  faBook,
  faBackpack, fasBackpack,
  faShoppingCart,
  faHandsHelping,
  faCog,
  faLocation,
  faCalendarDay,
  faCalendarAlt,
  faCalendarPlus,
  faCalendarEdit,
  faCalendarTimes,
  faUsersMedical,
  faUsersClass,
  faUsers,
  faChalkboardTeacher,
  faChalkboard,
  faTrophyAlt,
  faPoll,
  faCommentSmile,
  faPaintBrushAlt,
  faEye,
  faEyeSlash,
  faFileDownload,
  faUserClock,
  faHourglassStart,
  faHourglassEnd,
  faShapes,
  faBell,
  faBellExclamation,
  faHistory,
  faPalette,
  faPrint,
  faTrashAlt,
  faWaveSine,
  faHorizontalRule,
  faCircle,
  faLock,
  faLockOpen,
  faGraduationCap,
  faBirthdayCake,
  faSchool,
  faGrinTongueSquint,
  faCommentAltExclamation, fasCommentAltExclamation,
  faBooksMedical,
  faSun,
  faMoonStars,
  faPowerOff,
  faPennant,
  faUserCheck,
  faConstruction,
  faFileCheck,
  faFileEdit,
  faPen,
  fasCheck,
  faCheckCircle,
  faTimesCircle,
  faBolt,
  faCloud,
  faSunCloud,
  faClouds,
  faFog,
  faCloudsSun,
  faCloudSunRain,
  faCloudSun,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudDrizzle,
  faCloudHail,
  faSnowflakes,
  faWindsock,
  faHomeLg, fasHomeLg,
  faHeartCircle,
  faQuestionCircle,
  faSearch,
  fasHeart, farHeart,
  fasEllipsisV,
  faSync,
  faThLarge,
  faList,
  faPeopleArrows,
  fasInfo, farInfo,
  fasGripHorizontal,
  fasGripLines,
  faShareSquare,
  faTag,
  faFilePdf,
  faFileAlt,
  faFolders,
  faFolder,
  faFolderOpen,
  faUserPlus,
  fasReply,
  fasBold,
  fasItalic,
  fasListUl,
  fasListOl,
  fasUndo,
  fasRedo,
  faSave,
  faKeyboard,
  faFunction,
  faCalculatorAlt,
  faStickyNote,
  faCommentAltLines,
  faEdit,
  faPlusSquare,
  faAward,
  faInfoCircle,
  fasCircle,
  faBinoculars,
  faBookReader,
  fasCaretUp,
  fasCaretDown,
  fasInfinity,
  farFileCertificate
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
