<script>
import Logout from '@/mixins/logout'
export default {
  name: 'AccountWaiting',
  mixins: [Logout],
  methods: {
    logoutAndClear () {
      this.$emit('close')
      this.logout()
    }
  }
}
</script>

<template lang="pug">
  v-card.error()
    v-card-title.dialog-title.white--text {{ $t('compte.non-valide') }}
    v-card-text.white--text
      div.f5.mb3.tc {{ $t('compte.attente-validation') }}
    //- v-card-actions.secondary
    v-btn(@click='logoutAndClear', x-large, width='100%', color='secondary') {{ $t('action.fermer') }}
</template>

<style>
</style>
