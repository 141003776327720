import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
// import { ApolloLink } from 'apollo-link'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import VueApollo from 'vue-apollo'
import schemaPlus from './schema.json'
import schema from './schemaV2.json'
import schemaBoutique from './schemaBoutique.json'
import VueCookies from 'vue-cookies'

const fragmentMatcherPlus = new IntrospectionFragmentMatcher({
  schema: schemaPlus
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: { __schema: schema.__schema }
})

const fragmentMatcherBoutique = new IntrospectionFragmentMatcher({
  schema: schemaBoutique
})

const middlewareLinkPlus = setContext((req, { headers }) => {
  let auth = null
  if (VueCookies.get('EcoleDokomaUser') && VueCookies.get('EcoleDokomaUser').bearer && req?.operationName !== 'identification') {
    auth = 'Bearer ' + VueCookies.get('EcoleDokomaUser').bearer
  }

  if (req?.variables?.contextBearer) {
    auth = 'Bearer ' + req.variables.contextBearer
    delete req.variables.contextBearer
  }

  return {
    headers: {
      ...headers,
      'Accept-Language': 'FR-QC',
      Authorization: auth
    }
  }
})

const middlewareLinkBoutique = setContext((_, { headers }) => {
  let auth = null
  if (VueCookies.get('EcoleDokomaUser') && VueCookies.get('EcoleDokomaUser').bearer) {
    auth = 'Bearer ' + VueCookies.get('EcoleDokomaUser').bearer
  }
  return {
    headers: {
      ...headers,
      'Accept-Language': 'FR-QC',
      Authorization: auth,
      'X-BOUTIQUE': 'dokoma'
    }
  }
})

const httpLinkPlus = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/dokoma/graphql'
})

const httpLinkV2 = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/graphql/salle_classe'
})
const httpLinkBoutique = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/graphql/boutique'
})
export const apolloClientPlus = new ApolloClient({
  link: middlewareLinkPlus.concat(httpLinkPlus),
  cache: new InMemoryCache({ fragmentMatcher: fragmentMatcherPlus })
})

const cache = new InMemoryCache({ fragmentMatcher })
export const apolloClientV2 = new ApolloClient({
  link: middlewareLinkPlus.concat(httpLinkV2),
  cache,
  connectToDevTools: true
})

const cacheBoutique = new InMemoryCache({ fragmentMatcherBoutique })
export const apolloClientBoutique = new ApolloClient({
  link: middlewareLinkBoutique.concat(httpLinkBoutique),
  cache: cacheBoutique,
  connectToDevTools: true
})

Vue.use(VueApollo)

export default new VueApollo({
  clients: {
    plus: apolloClientPlus,
    v2: apolloClientV2,
    boutique: apolloClientBoutique
  },
  defaultClient: apolloClientV2
})
