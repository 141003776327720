<script>
export default {
  name: 'ClasseManagement'
}
</script>

<template lang="pug">
  .classe-management
    v-card-text
      h5 Gestion des classes
      div
        span Un espace pour voir le détail des classes et enlever/ajouter des élèves
</template>

<style>
</style>
