import Vue from 'vue'
import './plugins/fontawesome'

import App from './App.vue'
import router from './router'
import store from './store'
// import axios from './plugins/axios'
import vuetify from './plugins/vuetify'
import './plugins/vuedragscroll'
import './plugins/vueAuth.js'
import storeToComputed from './plugins/storeToComputed'
import '@babel/polyfill'
import VuePageTransition from 'vue-page-transition'
import './plugins/vueperfectscrollbar'
import VTooltip from 'v-tooltip'
import { i18n } from '@/setup/i18n.js'
import apolloProvider from '@/setup/apollo'
import VueCookies from 'vue-cookies'
import VueMatomo from 'vue-matomo'

import moment from 'moment'
import _ from 'lodash'

// => COMMENTER POUR L'INSTANT SUITE ERREUR SASS
// import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

process.env.COOKIES_HOST = process.env.NODE_ENV === 'production' ? '.dokoma.com' : window.location.hostname

// Vue.use(VueTelInputVuetify, {
//   Vuetify
// })
Vue.use(VueCookies)
Vue.use(VuePageTransition)
Vue.use(VTooltip)

Vue.use(require('vue-chartist'))
Vue.use(VueMatomo, {
  host: 'https://matomo.lichencommunications.com/',
  siteId: 15,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  debug: false
})
Vue.use(storeToComputed, store)
Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype._ = _

new Vue({
  store,
  apolloProvider,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
