<script>
export default {
  name: 'AjoutEnseignant',
  data () {
    return {
      accessCode: null
    }
  },
  methods: {
    cancel () {
      this.accessCode = null
      this.$store.commit('App/showAjoutEnseignant', false)
    },
    addTeacher () {
      console.log('AJOUT DE ENSEIGNANT')
    }
  }
}
</script>

<template lang="pug">
  .classe-management
    v-card-text
      h5 Ajout d'enseignants à mon école
      div
        v-text-field(v-model='accessCode', placeholder="Code d'accès de l'enseignant")
    v-card-actions
      v-btn(@click='addTeacher') Valider
      v-btn(@click='cancel') Annuler
</template>

<style>
</style>
