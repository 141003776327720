import Vue from 'vue'
import Vuex from 'vuex'
import App from './modules/app'
import User from './modules/user'
import Preferences from './modules/preferences'
import Compte from './modules/compte'
import Dialog from './modules/dialog'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    App,
    User,
    Preferences,
    Compte,
    Dialog
  }
})
