import vuetify from '@/plugins/vuetify'

const state = {
  darkMode: false,
  themeColor: null,
  themeColorDark: null,
  themeColorAccent: null,
  notificationsSidebar: false
}

const mutations = {
  setDarkMode (state, payload) {
    state.darkMode = payload
    vuetify.framework.theme.dark = payload
    vuetify.framework.theme.themes.dark.accent = '#423b56'
  },
  setThemeColor (state, payload) {
    if (payload) {
      state.themeColor = payload
      vuetify.framework.theme.themes.light.primary = payload
    }
  },
  setThemeColorAccent (state, payload) {
    if (payload) {
      state.themeColorAccent = payload
      vuetify.framework.theme.themes.light.accent = payload
    }
  },
  setThemeColorDark (state, payload) {
    if (payload) {
      state.themeColorDark = payload
      vuetify.framework.theme.themes.dark.primary = payload
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
