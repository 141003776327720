import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'
import axios from 'axios'

// function getRedirectUri (uri) {
//   try {
//     return !isUndefined(uri)
//       ? `${$window.location.origin}${uri}`
//       : window.location.origin
//   } catch (e) {}

//   return uri || null
// }

Vue.use(VueAxios, axios)
let baseUrl = null
let redirectUri = null
if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://ecole.dokoma.com/'
  redirectUri = 'https://ecole.dokoma.com/%23/fr/auth'
} else {
  if (process.env.VUE_APP_BASEROUTE === 'all') {
    baseUrl = 'http://localhost:8080/'
    redirectUri = 'http://localhost:8080/%23/fr/auth'
    // => on doit ajouter la locale venant du store
  } else {
    baseUrl = 'http://localhost:8080/#/'
    redirectUri = 'http://localhost:8080/%23/fr/auth'
    // => on doit ajouter la locale venant du store
  }
}

Vue.use(VueAuthenticate, {
  baseUrl,
  providers: {
    oauth2: {
      name: 'oauth2',
      clientId: '1c07537c-f984-47cf-8e07-9766ec876602',
      // display: 'popup',
      redirectUri, // Your client app URL
      tokenName: 'auth._token.social',
      authorizationEndpoint: ['https://oauth.dokoma.com/oauth/authorize'],
      scope: ['openid', 'profile', 'email'],
      responseType: 'code',
      state: () => Math.floor(Math.random() * 10 ** 10),
      requiredUrlParams: ['state', 'locale'],
      oauthType: '2.0',
      locale: 'fr' // => local par défaut
    }
  }
})
