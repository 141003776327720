export default {
  methods: {
    async createAccount (listUsagers) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/associerSalleClasseUsager.gql'),
          variables: {
            detail: listUsagers
          }
        })
        if (!data.associerSalleClasseUsager) {
          throw new Error('erreur associerSalleClasseUsager')
        } else {
          this.$store.dispatch('User/fetchRelations')
          this.$store.dispatch('App/setSnackBarNotification', {
            type: 'success',
            description: this.$t('alerte.creation-prof-success')
          })
          this.loading = false
          this.success = true
        }
      } catch (e) {
        console.error(e)
        this.loading = false
        this.error = true
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'error',
          description: this.$t('alerte.creation-compte-enseignant-error')
        })
      }
    },
    async ajoutUsagerToCompagnie (listUsagers) { // Deprecated ?
      console.log('ajout des usagers à la compagnie')
      let noError = false
      for (let i = 0; i < listUsagers.length && !noError; i++) {
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/createSalleClasseUsagerDemande.gql'),
            variables: {
              attrs: {
                recepteur: listUsagers[i].id,
                nature: 'SUPERIEUR'
              }
            }
          }).then(({ data }) => {
            if (!data.createSalleClasseUsagerDemande || data.createSalleClasseUsagerDemande.error) {
              throw data.createSalleClasseUsagerDemande.error || new Error('pas de data')
            }
          })
        } catch (e) {
          noError = true
          this.loading = false
          console.error(e)
          this.error = true
          this.$store.dispatch('App/setSnackBarNotification', {
            type: 'error',
            description: this.$t('alerte.demande-ajout-error')
          })
        }
      }
      if (!noError) {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'success',
          description: this.$t('alerte.demande-ajout-succes')
        })
        this.loading = false
        this.success = true
      }
    }
  }
}
