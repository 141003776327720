<script>
import RechercheEnseignants from '@/components/profile/rechercheEnseignants'
import CustomNameList from '@/components/shared/customNameList'
import handleUser from '@/mixins/handleUser'

export default {
  name: 'SansRelation',
  mixins: [handleUser],
  components: {
    RechercheEnseignants,
    CustomNameList
  },
  data () {
    return {
      step: 0,
      listUserToCreate: [],
      ajout: false,
      success: false,
      error: false
    }
  },
  computed: {
    compagnieName () {
      return this.$store.getters['Compte/compagnieName']
    },
    titreDialog () {
      if (this.step === 0) {
        return this.$t('compte.sans-relation')
      } else if (this.step === 1) {
        return this.$t('action.approuver-enseignants')
      } else if (this.step === 2) {
        return this.$t('action.creation-compte-enseignant')
      } else if (this.step === 3) {
        return this.$t('partage.creation-en-cours')
      } else {
        return 'autre'
      }
    },
    canCreateUser () {
      if (this.listUserToCreate.length === 0) {
        return false
      }
      const listUser = this.listUserToCreate.every((e) => {
        if (e?.info) {
          return (!e.info.prenom || !e.info.nom)
        } else {
          return e.courriel && /.+@.+/.test(e.courriel)
        }
      })

      return listUser
    }
  },
  methods: {
    setListUser (value) {
      this.listUserToCreate = value
    },
    async goStep (step) {
      this.step = step
      if (step === 2) {
        this.listUserToCreate = []
        this.ajout = true
      } else if (step === 1) {
        this.ajout = false
        this.listUserToCreate = []
      } else if (step === 3) {
        this.success = false
        this.error = false
        if (this.ajout) {
          await this.createAccount(this.listUserToCreate)
        } else {
          await this.ajoutUsagerToCompagnie(this.listUserToCreate)
        }
        this.refetchCompte()
      }
    },
    refetchCompte () {
      // The new users might take a second to get updated, so we wait before refreshing
      setTimeout(() => {
        this.$store.dispatch('Compte/fetchCompte')
      }, 2000)
    }
  }
}
</script>

<template lang="pug">
  v-card.light
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click="$emit('close')", depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text {{ titreDialog }}
    v-card-text.black--text

      //- Step 0:  Choose between approval or creation
      template(v-if='step === 0')
        div.f5.mb3.tc(v-html="$t('compte.instruction-sans-relation', { compagnie: compagnieName })")
        .flex.space-between.w-100.action-sans-relation
          v-btn.mr2(color='secondary', x-large, @click='goStep(1)') {{ $t('action.approuver-enseignants')}}
          v-spacer
          v-btn.mr2.button-deux(color='secondary', x-large, @click='goStep(2)') {{ $t('action.creation-compte-enseignant') }}
      template(v-else)
        v-btn(v-if='step !== 3', color='primary', @click='goStep(0)') {{ $t('action.retour') }}

        //- Step 1: Select teachers that need an approval
        template(v-if='step === 1')
          recherche-enseignants(@setList='setListUser', creation=true @refetch="refetchCompte")

        //- Step 2: Create teachers account from email and names
        template(v-else-if='step === 2')
          custom-name-list.dialog-text.ma2(@setList='setListUser')
          v-btn.mt3(width='100%' rounded color='secondary' x-large :disabled='!canCreateUser' @click='goStep(3)') {{ $t('action.creation-compte-enseignant') }}

        //- Step 3 : Confirmation
        template(v-if='step === 3')
          .loading-creation.ma2.tc
            template(v-if='!success && !error')
              v-progress-circular(indeterminate, color='primary')
              div.f4.mt3.b {{ $t('partage.ajout-enseignants') }}
            template(v-else-if='success')
              div
                .f4.mt3.b {{ $t('alerte.creation-prof-success') }}
              .actions.mt3
                v-btn(color='primary', @click="$emit('close')") {{ $t('action.terminer') }}
            template(v-else-if='error')
              div
                .f4.mt3.b {{ $t('alerte.creation-prof-error') }}
              .actions.mt3
                v-btn(color='primary', @click="$emit('close')") {{ $t('action.terminer') }}

</template>

<style lang='sass'>
@media all and (max-width: $medium)
  .action-sans-relation
    flex-direction: column
  .button-deux
    margin-top: .5rem
</style>
