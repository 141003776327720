/*
This is a Lichen plugin made to lighten the code,
especially in the Components Computed properties.
It also provide a single place where they are defined,
avoiding repetition between all components using the
same properties from the store.

Reference :
https://stackoverflow.com/questions/53089441/how-to-access-vuex-from-vue-plugin
*/

// Make store globally accessible from all this file
let s

export default function install (Vue, store) {
  s = store
  Vue.prototype.storeToComputed = storeToComputed
}

export const storeToComputed = (propertiesList) => {
  if (!Array.isArray(propertiesList)) {
    Error('storeToCommit argument must be an array.')
  }
  return Object.keys(registeredComputed)
    .filter(key => propertiesList.includes(key))
    .reduce((obj, key) => {
      obj[key] = registeredComputed[key]
      return obj
    }, {})
}

const registeredComputed = {
  windowSize: {
    set (val) { s.commit('App/setWindowSize', val) },
    get () { return s.state.App.window }
  },
  mobileTemplate: {
    get () { return s.state.App.mobileTemplate }
  },
  user: {
    set (val) { s.commit('App/setUser', val) },
    get () { return s.state.User.user }
  },
  compte: {
    get () { return s.state.Compte.compte }
  }
}
