<script>
import logout from '@/mixins/logout'

export default {
  name: 'LayoutTopBanner',
  mixins: [logout],
  computed: {
    correctProfil () {
      return this.$store.state.User.connected && this.$store.state.User.user
    }
  }
}
</script>

<template lang="pug">
  v-app-bar.px-6(fixed, dense, height='40', style='z-index:100 !important;')
    v-btn.mr3.ml3(v-if='correctProfil', @click='logout', icon, x-small, color='light', v-tooltip.bottom="{ content: 'Déconnexion', offset:'10px'}")
      v-hover(v-slot:default='{ hover }')
        font-awesome-icon.f4(:icon="['fad', 'power-off']", :class="{ 'animated flash infinite red--text text--darken-2': hover }")
    router-link.no-underline.site-logo.tc(to='/', style='line-height:1;', class='masuperclasse')
      img(src='~@/assets/images/logos/dokoma-white.svg', height='20')
</template>

<style lang="sass" scoped>
.v-app-bar.v-toolbar
  background-color: var(--v-primary-base) !important
.v-toolbar__content
  padding: 0 4%
.masuperclasse
  margin: auto
</style>
