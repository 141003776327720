<script>
import AjoutEnseignant from '@/components/dialogs/ajoutEnseignant'
import RetirerEnseignant from '@/components/dialogs/retirerEnseignant'
import DistributionCredit from '@/components/dialogs/distributionCredit'
import ClasseManagement from '@/components/dialogs/classeManagement'
import AccountWaiting from '@/components/dialogs/accountWaiting.vue'
import SansRelation from '@/components/dialogs/sansRelation.vue'
export default {
  name: 'GlobalDialog',
  components: {
    AjoutEnseignant,
    RetirerEnseignant,
    DistributionCredit,
    ClasseManagement,
    AccountWaiting,
    SansRelation
  },
  computed: {
    showDialog: {
      get () { return this.$store.state.App.globalDialog.value },
      set (val) {
        if (this.context === 'ajoutEnseignant') {
          this.$store.commit('App/showAjoutEnseignant', val)
        } else if (this.context === 'distributeCredit') {
          this.$store.commit('App/showDistributeCredits', val)
        } else if (this.context === 'classeManagement') {
          this.$store.commit('App/showClasseManagement', val)
        } else if (this.context === 'retirerEnseignant') {
          this.$store.commit('App/showRetirerEnseignant', val)
        } else {
          this.$store.commit('App/setGlobalDialog', val)
        }
      }
    },
    context () {
      return this.$store.state.App.globalDialog.context
    }
  },
  methods: {
    closeDialog () {
      this.$store.commit('App/setGlobalDialog', { value: false, context: null })
    }
  }
}
</script>

<template lang="pug">
  v-dialog(v-if='showDialog', v-model='showDialog', max-width='700', content-class='custom-dialog', persistent)
    ajout-enseignant(v-if='context === "ajoutEnseignant"')
    retirer-enseignant(v-else-if='context === "retirerEnseignant"'  @close='closeDialog')
    distribution-credit(v-else-if='context === "distributeCredit"')
    classe-management(v-else-if='context === "classeManagement"')
    account-waiting(v-else-if='context === "waitingValidation"', @close='closeDialog')
    sans-relation(v-else-if='context === "sansRelation"', @close='closeDialog')
    template(v-else)
      v-card
        v-card-text
          h5 Dialog sans contexte {{context}}
</template>

<style>
</style>
