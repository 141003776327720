import { apolloClientV2 } from '@/setup/apollo.js'

const state = {
  compte: null
}

const getters = {
  enseignants: (state) => {
    return state.compte?.comptesUsagers
      ?.filter(u => u.nature === 'Enseignant')
      ?.map(u => u.usager)
      ?.map(u => ({ ...u, name: `${u?.info?.prenom} ${u?.info?.nom}` })) ?? []
  },
  dataEnseignants: (state) => {
    return state.compte?.dataEnseignants ?? []
  },
  dataSalles: (state) => {
    return state.compte?.dataSalles ?? []
  },
  dataEleves: (state) => {
    return state.compte?.dataEleves ?? []
  },
  compagnieName: (state) => {
    return state.compte?.compagnie?.titre ?? ''
  }
}

const mutations = {
  setCompte (state, payload) {
    state.compte = payload
  }
}

const actions = {
  async fetchCompte ({ commit }) {
    try {
      const { data: { salleClasseCompte } } = await apolloClientV2.query({
        query: require('@/graphql/queries/v2/salleClasseCompte.gql'),
        fetchPolicy: 'no-cache'
      })
      // Parse JSON Stats Strings
      if (salleClasseCompte?.dataSalles && typeof salleClasseCompte.dataSalles === 'string') {
        salleClasseCompte.dataSalles = JSON.parse(salleClasseCompte.dataSalles)
      }
      if (salleClasseCompte?.dataEleves && typeof salleClasseCompte.dataEleves === 'string') {
        salleClasseCompte.dataEleves = JSON.parse(salleClasseCompte.dataEleves)
      }
      if (salleClasseCompte?.dataEnseignants && typeof salleClasseCompte.dataEnseignants === 'string') {
        salleClasseCompte.dataEnseignants = JSON.parse(salleClasseCompte.dataEnseignants)
      }
      commit('setCompte', salleClasseCompte)
    } catch (err) {
      console.error('Error while loading Compte :', err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
